import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={() => <Home authenticated={true} />} />
        <Route path='' component={() => <NotFound />} />
      </Switch>
    </Router>
  );
}

export default App;
