import React from "react";
import "./VerticalModal.css";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/Button/Button";

const VerticalModal = (props) => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className={` ${props.dark ? `dark` : ""}`}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Buy coins</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Coming Soon to Your Country</h3>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerticalModal;
