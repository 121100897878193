import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { EmailIcon } from "../../../../components/Icons/Icons";
import Button from "../../../../components/Button/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import classes from "./SimpleForm.module.css";

const SimpleForm = (props) => {
  const [subscribe, setSubscribe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubscribe(true);
  }

  const handleChange = (e) => {
    setSubscribe(false);
  }

  return (
    <>
      <Form action="https://app.us6.list-manage.com/subscribe/post" method="POST" noValidate>
        <Form.Row className={classes.wrapper}>
          <input type="hidden" name="u" value="dc8987f597843fe3286fbdbf0" />
          <input type="hidden" name="id" value="7ed5e9eaa0" />
          <div>
            <CustomInput
              classes={`${classes.item} ${props.classes}`}
              type="email"
              name="EMAIL"
              id="MERGE0"
              dark={props.dark ? true : null}
              placeholder={`Type your e-mail`}
              simple={false}
              leftIcon={true}
              icon={<EmailIcon />}
              autoCapitalize="off"
              autoCorrect="off"
            />
          </div>
          <div>
            {subscribe ? (
              <Button
                id="mc-embedded-subscribe"
                classes={"secondary large simple w-100"}
                value={"Subscribed!"}
                disabled
              />
            ) : (
              <Button
                id="mc-embedded-subscribe"
                classes={"secondary large simple w-100"}
                value={"Subscribe"}
                type='submit'
              />
            )}
          </div>
        </Form.Row>
        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true' aria-label="Please leave the following three fields empty">
          <label htmlFor="b_name">Name: </label>
          <input type="text" name="b_name" tabIndex="-1" value="" placeholder="Freddie" id="b_name" />

          <label htmlFor="b_email">Email: </label>
          <input type="email" name="b_email" tabIndex="-1" value="" placeholder="youremail@gmail.com" id="b_email" />

          <label htmlFor="b_comment">Comment: </label>
          <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment"></textarea>
        </div>
      </Form>
    </>
  );
};

export default SimpleForm;
