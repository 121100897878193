import React from "react";

import { Container, Col, Row } from "reactstrap";

import {
  SmartCatLogo,
  Google,
  LinkedIn,
  Twitter,
} from "../../../components/Icons/Icons";
import Socials from "../../../components/Socials/Socials";
import Divider from "../../../components/Divider/Divider";
import Button from "../../../components/Button/Button";
import classes from "./FootersLayoutsTypeCat.module.css";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { companyName, companyAddress, companyEmail } from '../../../services/siteConfig';

function FootersLayoutsTypeCat(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section
        className={`section  ${classes.footer} ${props.dark ? `dark ${classes.dark}` : ""}`}
        id='contact'
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg='3' md='4'>
                <Fade top triggerOnce={true}>
                  <a href='#home' className={`${classes.logotype}`}>
                    <div className='flex justify-center'>
                      <SmartCatLogo fill='transparent' width='120' />
                    </div>
                  </a>
                </Fade>
                <Fade top delay={50} triggerOnce={true}>
                  <p className={classes.description}>{t("FOOTER_DESCRIPTION")}</p>
                </Fade>
                <Fade top delay={100} triggerOnce={true}>
                  <a href={`mailto:${companyEmail}`} >
                    <Button
                      classes={`primary small simple ${classes.button}`}
                      value={t("FOOTER_BUTTON")}
                    ></Button>
                  </a>
                </Fade>
              </Col>
              <Col
                lg={{ size: 7, offset: 2 }}
                md={{ size: 7, offset: 1 }}
                className={classes.rightColumn}
              >
                <Row>
                  <Col lg='4' md='6' sm='5' xs='6' className={classes.item}>
                    <Fade top delay={100} triggerOnce={true}>
                      <header>{t("QUICK_LINKS")}</header>
                      <ul className={classes.companyList}>
                        <li className={classes.companyItem}>
                          <a href='#home'>{t("HOME")}</a>
                        </li>
                        <li className={classes.companyItem}>
                          <a href='#token'>{t("TOKEN")}</a>
                        </li>
                        <li className={classes.companyItem}>
                          <a href='#invest'>{t("INVEST")}</a>
                        </li>
                      </ul>
                    </Fade>
                  </Col>
                  <Col lg='3' md='6' sm='4' xs='6' className={classes.item}>
                    <Fade top delay={150} triggerOnce={true}>
                      <header>{t("TECHNOLOGY_PARTNERS")}</header>
                      <ul className={classes.companyList}>
                        <li className={classes.companyItem}>
                          <a href='https://unhackablecloud.com/' target='_blank' rel='noopener noreferrer'>CAT, Inc</a>
                        </li>
                      </ul>
                    </Fade>
                  </Col>
                  <Col lg='5' md='8' className={classes.item}>
                    <Fade top delay={200} triggerOnce={true}>
                      <header>{t("CONTACTS")}</header>
                      <div className={classes.content}>
                        <p className={classes.shortDescription}>
                          {companyName}
                        </p>
                        <p className={classes.shortDescription}>
                          {companyAddress}
                        </p>
                        <a
                          href={`mailto:${companyEmail}`}
                          className={classes.boldLink}
                        >
                          {companyEmail}
                        </a>
                      </div>
                    </Fade>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className={classes.dividerRow}>
              <Col>
                <Fade top delay={250} triggerOnce={true}>
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                </Fade>
              </Col>
            </Row>
            <Row>
              <Col className='col-auto'>
                <Fade top delay={300} triggerOnce={true}>
                  <p className={classes.copyright}>Copyright © 2021-2022</p>
                </Fade>
              </Col>
              <Col
                lg='3'
                md='3'
                sm='4'
                className={`ml-auto d-flex align-items-center justify-content-end ${classes.socials}`}
              >
                <Fade top delay={350} triggerOnce={true}>
                  <Socials dark={props.dark ? true : false}>
                    <a
                      href='https://twitter.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Twitter />
                    </a>
                    <a
                      href='https://linkedin.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <LinkedIn />
                    </a>
                    <a
                      href='https://google.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Google />
                    </a>
                  </Socials>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FootersLayoutsTypeCat;
