import React from "react";
import classes from "./FeaturesLayoutsTypeAboutCoin.module.css";

import VerticalModal from "./VerticalModal/VerticalModal";
import Features from "./Features/Features";
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import { Download } from "../../../components/Icons/Icons";
import Divider from "../../../components/Divider/Divider";
import { whitepaperURL } from '../../../services/siteConfig';
import LottieAnimation from "../../HeroLayouts/HeroLayoutsTypeEleventh/Lottie";
import iotAnimation from "../../../img/andy.json";

function FeaturesLayoutsTypeAboutCoin(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section
        className={`section ${classes.features} ${props.dark ? `dark ${classes.dark}` : ""
          }`}
        id='token'
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg='6' md='6' className={classes.leftColumn}>
                <Fade top triggerOnce={true}>
                  <h1>{t("FEATURE_TITLE")}</h1>
                </Fade>
                <Fade top delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {t("FEATURE_DECRIPTION")}
                  </p>
                </Fade>

                <div className={classes.playButton}>
                  <Fade top delay={100} triggerOnce={true}>
                    <Button
                      classes={`primary large right-icon ${classes.button}`}
                      value={t("DOWNLOAD_WHITEPAPER")}
                      rightIcon={<Download fill='#ffffff' />}
                      link
                      onClick={() =>
                        window.open(
                          whitepaperURL,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    />
                  </Fade>
                </div>
              </Col>

              <Col
                lg='6'
                md='6'
                className={`position-relative ${classes.rightColumn}`}
              >
                <Fade top triggerOnce={true}>
                  <LottieAnimation lotti={iotAnimation} />
                </Fade>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg='12' md='12'>
                <Fade top delay={150} triggerOnce={true}>
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                </Fade>
              </Col>
            </Row>
            <Features />
            <VerticalModal
              dark={props.dark ? true : false}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}

export default FeaturesLayoutsTypeAboutCoin;
