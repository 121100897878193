import React from "react";
import classes from "./FeaturesTypeFirst.module.css";

const FeaturesTypeFirst = (props) => {
  return (
    <div className={classes.feature}>
      <h2 className={`title ${classes.title}`}>{props.title}</h2>
      <p className={`normal-text ${classes.description}`}>
        {props.description}
      </p>
    </div>
  );
};

export default FeaturesTypeFirst;
