import React from "react";
import { Form } from "react-bootstrap";
import cls from "./Form.module.css";

import { Col } from "reactstrap";
import Button from "../../../../components/Button/Button";
import { Flash } from "../../../../components/Icons/Icons";

import { useTranslation } from "react-i18next";

import { siteRegisterURL } from "../../../../services/siteConfig";

const Forms = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={cls.form}>
        <Form.Row className='align-items-center'>
          <Col xs='auto'>
            <Button
              onClick={() =>
                window.open(
                  `${siteRegisterURL}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              classes={"secondary large left-icon"}
              value={t("GET_STARTED")}
              leftIcon={<Flash fill='#ffffff' />}
            />
          </Col>
        </Form.Row>
      </div>
    </React.Fragment>
  );
};

export default Forms;
