import { Row, Col, Container } from "reactstrap";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";

import classes from "./TestimonialsLayoutsTypeEighth.module.css";
import Testimonial from "./Testimonial/TestimonialTypeEighth";

import Slider from "./Slider/Slider";
import { Avatar } from "antd";
import { User } from "../../../components/Icons/Icons";

class TestimonialsLayoutsTypeEighth extends Component {
  state = {
    persons: [
      {
        name: "Ralph Waldo Emerson",
        post: "Famous American essayist",
        description:
          "“The desire of gold is not for gold. It is for the means of freedom and benefit.",
        link: "/some-link",
        avatar: null,
      },
      {
        name: "Amelia Earhart",
        post: "Famous aviator",
        description:
          "The most difficult thing is the decision to act, the rest is merely tenacity.",
        link: "/some-link",
        avatar: null,
      },
    ],
  };

  renderCards() {
    const card = this.state.persons;
    const listItems = card.map((item, index) => {
      return (
        <Testimonial
          key={index}
          person={item.name}
          post={item.post}
          description={item.description}
        >
          {item.avatar ? (
            <Avatar size={160} src={item.avatar} />
          ) : (
            <Avatar
              size={160}
              icon={<User fill={"#ffffff"} />}
              style={{
                fontSize: 0,
                backgroundColor: "#345DEE",
              }}
            />
          )}
        </Testimonial>
      );
    });
    return <Slider array={listItems} dark={this.props.dark ? true : false} />;
  }

  render() {
    return (
      <div>
        <section
          className={`section ${classes.testimonials} ${this.props.dark ? `dark ${classes.dark}` : ""
            }`}
        >
          <div className={classes.gridWrapper}>
            <Container>
              <Row className={classes.testimonialsRow}>
                <Col className='col-12'>{this.renderCards()}</Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    );
  }
}

export default TestimonialsLayoutsTypeEighth;
