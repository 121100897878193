import { Row, Col, Container } from "reactstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.css";
import Slider from "./Slider/Slider";
import { Fade } from "react-awesome-reveal";
import classes from "./PricingLayoutsTypeEighth.module.css";
import Pricing from "./Pricing/PricingTypeSecond";
import CountDownTimer from "../../../components/CountDownTimer/CountDownTimer";
import Logos from "./Logos/Logos";
import VerticalModal from "./VerticalModal/VerticalModal";

function PricingLayoutsTypeEighth(props) {
  const [modalShow, setModalShow] = useState(false);

  const { t } = useTranslation();

  const pricing = [
    {
      type: t("PRIVATE_SALE"),
      id: 1,
      descr: t("PRIVATE_SALE_DESCRIPTION"),
      firstLink: "#/some-link#1",
      secondLink: "#/some-link#2",
      cost: "$1",
    },
    {
      type: t("PRE_SALE"),
      id: 2,
      descr: t("PRE_SALE_DESCRIPTION"),
      firstLink: "#/some-link#3",
      secondLink: "#/some-link#4",
      cost: "$2",
    },
    {
      type: t("ICO_SALE"),
      id: 3,
      descr: t("ICO_SALE_DESCRIPTION"),
      firstLink: "#/some-link#4",
      secondLink: "#/some-link#5",
      cost: "TBD",
    },
  ];

  const handleClick = () => {
    setModalShow(true);
  };

  const renderCards = () => {
    const card = pricing;
    const listItems = card.map((item, index) => {
      return (
        <div className={classes.item}>
          <Fade down delay={`${index}0`} triggerOnce={true}>
            <Pricing
              title={item.type}
              descr={item.descr}
              cost={item.cost}
              firstLink={item.firstLink}
              secondLink={item.secondLink}
              dark={props.dark ? true : false}
              handleClick={handleClick}
            />
          </Fade>
        </div>
      );
    });
    return <Slider array={listItems} />;
  };

  return (
    <section
      className={`section ${classes.pricing} ${props.dark ? `dark ${classes.dark}` : ""}`}
    >
      <div className={classes.wrapper}>
        <Container>
          <Row className={classes.headerRow}>
            <Col lg='6' md='6' className='mx-auto'>
              <Fade top triggerOnce={true}>
                <h1 className='title text-center'>{t("COIN_SALE")}</h1>
              </Fade>
            </Col>
          </Row>
          <Row className={classes.countdown_wrapper}>
            <CountDownTimer />
          </Row>
          <Row className={classes.pricing}>
            <Col className={`${classes.col}`}>{renderCards()}</Col>
          </Row>
          <Row className={classes.countdown_wrapper}>
            <Col className='col-12'>
              <Fade top delay={150} triggerOnce={true}>
                <div className={classes.divider}>
                  <p className='normal-text text-center'>
                    {t("WE_ACCEPT")}:{" "}
                  </p>
                </div>
              </Fade>
            </Col>
            <Col className='col-12'>
              <Fade top delay={200} triggerOnce={true}>
                <div className={classes.logos}>
                  <Logos dark={props.dark} />
                </div>
              </Fade>
            </Col>
          </Row>
          <VerticalModal
            dark={props.dark ? true : false}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </Container>
      </div>
    </section>
  );
}

export default PricingLayoutsTypeEighth;
