import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en_us: {
        translations: {
          HOME: "Home",
          INVEST: "Invest",
          TOKEN: "Token",
          ABOUT: "About",
          CONTACT: "Contact",
          REFER: "Refer",
          DASHBOARD: "DASHBOARD",
          LOGIN: "Login",
          SUBMIT: "Submit",
          GET_STARTED: "Get Started",
          PLAY_VIDEO: "Play Video",
          EMAIL_PLACEHOLDER: "Type your e-mail",
          GO_TO_APP: "Go to App",

          HERO_HEADER: "CryptoCat Coin 1.0",
          HERO_TITLE:
            "Smart Money for Smart City: leading the evolutionary revolution of new financial world order",
          TERMS_AND_CONDITIONS:
            "By clicking the button you confirm that you're agree with our Terms and Conditions of Use.",

          FEATURE_TITLE: "A new way to invest in the digital automated wealth growth generation",
          FEATURE_DECRIPTION:
            "It is a green coin with no mining required, unlike bitcoin. Utilizes an automated clean, fast coin generation for accelerated revenue growth.",
          DOWNLOAD_WHITEPAPER: "Download Whitepaper",

          FEATURE1: "ERC-1363 Compliant",
          FEATURE1_DESCRIPTION:
            "The 'Payable' token made with latest Ethereum standard",
          FEATURE2: "Secure Storage",
          FEATURE2_DESCRIPTION:
            "Secure eWallets for your cryptocurrencies and you are in complete control of them",
          FEATURE3: "Decentralized Operation",
          FEATURE3_DESCRIPTION:
            "Ethereum based distributed network, no central authority",
          FEATURE4: "Ultra Hybrid NFT",
          FEATURE4_DESCRIPTION:
            "Supports Non-Fungible Tokens for NFT Auction, Market in NFT, Non-NFT, Semi-NFT formats",
          FEATURE5: "User centric",
          FEATURE5_DESCRIPTION:
            "User centric operation, bringing together global network of SmartCat club members",
          FEATURE6: "Smart Ventures",
          FEATURE6_DESCRIPTION:
            "Part of Smart Ventures: SmartCat Club, Smart Ocean, Smart City, Smart Accommodations, Smart Entertainment, Smart Market",

          COIN_FEATURE1: "1.5 Billion",
          COIN_FEATURE1_DESCRIPTION: "Total CCAT Coins",
          COIN_FEATURE2: "40%",
          COIN_FEATURE2_DESCRIPTION: "Coins to be issued",
          COIN_FEATURE3: "$450 Million",
          COIN_FEATURE3_DESCRIPTION: "Total Marketcap",
          COIN_FEATURE4: "10",
          COIN_FEATURE4_DESCRIPTION: "Total ICO stages",
          COIN_FEATURE5: "150 Million",
          COIN_FEATURE5_DESCRIPTION: "Initial supply",
          COIN_FEATURE6: "1st",
          COIN_FEATURE6_DESCRIPTION: "Current ICO stage",

          PRE_SALE_TITLE: "Private Sale ends in",
          PRE_SALE_END_TITLE: "Private Sale has ended!",
          DAYS: "Days",
          HOURS: "Hours",
          MINS: "Mins",
          SECS: "Secs",
          COIN_SALE: "Coin Sale",
          PRIVATE_SALE: "Private Sale",
          PRIVATE_SALE_DESCRIPTION:
            "Our best price for invitation-only clients",
          PRE_SALE: "Pre Sale",
          PRE_SALE_DESCRIPTION:
            "For new customers with lots of early bird benefits",
          ICO_SALE: "ICO",
          ICO_SALE_DESCRIPTION: "Fair market Price offerred right here",
          BUY_COINS: "Buy Coins",
          LEARN_MORE: "Learn More",
          WE_ACCEPT: "We accept",

          SUBSCRIBE_TITLE: "Coming Soon! Invite friends & earn rewards",
          SUBSCRIBE_DESCRIPTION: "Subscribe to our newsletter to get news about new features including upcoming referral rewards!",

          FOOTER_TITLE:
            "Smart Coin for Smart City! Ushering new financial revolution",
          FOOTER_DESCRIPTION: "Smart Money for Smart City. Be the part of new financial revolution.",
          FOOTER_BUTTON: "Ask Question",
          QUICK_LINKS: "Quick Links",
          TECHNOLOGY_PARTNERS: "Technology Partners",
          CONTACTS: "Contacts",


          REWARDS: "Rewards",
          LOGOUT: "Logout",
        },
      },
      zh_cn: {
        translations: {
          HOME: "家",
          INVEST: "投资",
          TOKEN: "代币",
          ABOUT: "关于",
          CONTACT: "联系",
          REFER: "推荐",
          DASHBOARD: "控制面板",
          LOGIN: "登录",
          SUBMIT: "提交",
          GET_STARTED: "从这里开始",
          PLAY_VIDEO: "播放视频",
          EMAIL_PLACEHOLDER: "输入你的e-mail",
          GO_TO_APP: "跳转应用程序",

          HERO_HEADER: "CryptoCat Coin 1.0",
          HERO_TITLE:
            "智慧城市的智慧币：引领金融世界新秩序的进化革命",
          TERMS_AND_CONDITIONS:
            "同意我们的条款和条件，请按键。",

          "Be the part new financial revolution - Smart Money for Smart City":
            "加入新的金融变革 —— 智慧城市的智慧货币",
          "By clicking the button you confirm that you agree to the User Agreement and Privacy Policy.":
            "同意我们的条款和条件，请按键。",
          "Smart Coin 1.0": "智慧币 1.0",

          FEATURE_TITLE: "投资自动化数字财富增长的新方式",
          FEATURE_DECRIPTION:
            "与比特币不同，它是一种无需挖矿的绿色硬币。利用自动清理、快速硬币生成来加速收入增长。",
          DOWNLOAD_WHITEPAPER: "下载白皮书",

          FEATURE1: "符合ERC-1363标准的",
          FEATURE1_DESCRIPTION:
            "可支付代币符合最新以太坊标准",
          FEATURE2: "安全存储",
          FEATURE2_DESCRIPTION:
            "加密货币的安全的电子钱包尽在您的掌握",
          FEATURE3: "去中心化操作",
          FEATURE3_DESCRIPTION:
            "以太坊为基础的分布式网络， 没有中央主管",
          FEATURE4: "极致混合的非同质化代币",
          FEATURE4_DESCRIPTION:
            "以非同质化代币、同质化代币和半同质化代币的形式，在电子拍卖、电子商务中支持非同质化代币NFT",
          FEATURE5: "以用户为中心",
          FEATURE5_DESCRIPTION:
            "以用户为中心，连接全球海员俱乐部成员",
          FEATURE6: "智慧风险投资",
          FEATURE6_DESCRIPTION:
            "加入智慧风险投资：智慧海员俱乐部，智慧海洋，智慧城市，智慧住宿，智慧娱乐，智慧市场",

          COIN_FEATURE1: "15亿",
          COIN_FEATURE1_DESCRIPTION: "CCAT 代币总数",
          COIN_FEATURE2: "40%",
          COIN_FEATURE2_DESCRIPTION: "发行的代币",
          COIN_FEATURE3: "4.5 亿美元",
          COIN_FEATURE3_DESCRIPTION: "总市值",
          COIN_FEATURE4: "10",
          COIN_FEATURE4_DESCRIPTION: "ICO 阶段总数",
          COIN_FEATURE5: "1.5亿",
          COIN_FEATURE5_DESCRIPTION: "初始供应",
          COIN_FEATURE6: "第一个",
          COIN_FEATURE6_DESCRIPTION: "当前ICO阶段",

          PRE_SALE_TITLE: "预售结束于",
          PRE_SALE_END_TITLE: "Private Sale has ended!",
          DAYS: "天",
          HOURS: "小时",
          MINS: "分钟",
          SECS: "秒",
          COIN_SALE: "出售币",
          PRIVATE_SALE: "私售",
          PRIVATE_SALE_DESCRIPTION:
            "我们提供给受邀用户的最低价格",
          PRE_SALE: "预售",
          PRE_SALE_DESCRIPTION:
            "给新用户众多抢先权益",
          ICO_SALE: "ICO",
          ICO_SALE_DESCRIPTION: "此处提供公平市价",
          BUY_COINS: "购币",
          LEARN_MORE: "了解更多",
          WE_ACCEPT: "可使用",

          SUBSCRIBE_TITLE: "即将推出！邀请朋友，赢取奖励",
          SUBSCRIBE_DESCRIPTION: "订阅我们的新闻，第一时间了解新功能以及将要推出的奖励机制！",

          FOOTER_TITLE:
            "智慧城市的智慧币！迎来新的金融变革",
          FOOTER_DESCRIPTION: "智慧城市的智慧货币。加入新的金融变革。",
          FOOTER_BUTTON: "提问",
          QUICK_LINKS: "快速链接",
          TECHNOLOGY_PARTNERS: "技术合作伙伴",
          CONTACTS: "联系方式",

          REWARDS: "奖励",
          LOGOUT: "退出"
        },
      },
    },
    lng: "zh_cn",
    fallbackLng: "zh_cn",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    useSuspense: false,

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
