import React from "react";

import { Container, Col, Row } from "reactstrap";

import Forms from "./Form/Form";
import Header from "./Header/Header";
import Socials from "./Socials/Socials";
import classes from "./HeroLayoutsTypeEleventh.module.css";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import { CryptoCatLogo } from '../../../components/Icons/Icons';

// 399 for small screens 599 for big
function HeroLayoutsTypeEleventh(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section
        className={`${classes.hero} ${props.dark ? `dark ${classes.dark}` : ""}`}
        id='home'
      >
        <div className={classes.gridWrapper}>
          <Container className={classes.containerHeader}>
            <Header dark={props.dark} authenticated={props.authenticated} />
          </Container>
          <Container>
            <Row className={classes.contentRow}>
              <Col lg='6' className={classes.leftColumn}>
                <div className={classes.heroPic}>
                  <Fade top triggerOnce={true}>
                    <CryptoCatLogo height="650" />
                  </Fade>
                </div>
              </Col>

              <Col lg='6'>
                <strong>
                  {t('HERO_HEADER')}
                </strong>
                <h1>
                  {t('HERO_TITLE')}
                </h1>
                <div className={classes.form}>
                  <Forms dark={props.dark} />
                </div>
                <p className={`normal-text ${classes.description}`}>
                  {t('TERMS_AND_CONDITIONS')}
                </p>
              </Col>

              <Col className={classes.bottomSection}>
                <Row>
                  <Col
                    lg='6'
                    md='6'
                    sm='6'
                    xs='12'
                    className={classes.firstCol}
                  />
                  <Col lg='6' md='6' sm='6' xs='12'>
                    <Socials />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default HeroLayoutsTypeEleventh;
