import React from "react";
import classes from "./Header.module.css";
import { Nav, Navbar } from "react-bootstrap";
import { SmartCatLogoDark } from "../../../../components/Icons/Icons";
import Button from "../../../../components/Button/Button";
import Socials from "../Socials/Socials";
import { useTranslation } from "react-i18next";
import { siteURL } from "../../../../services/siteConfig";

const Header = (props) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <section
      className={`${classes.header} ${props.dark ? `dark ${classes.dark}` : ""
        }`}
    >
      <Navbar collapseOnSelect expand='lg' className='p-0' style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div className={classes.wrapper}>
          <Navbar.Brand href='/' className='inline-block'>
            <SmartCatLogoDark width='90' />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            className='custom-toggler'
          />
        </div>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link href='#token'>{t("TOKEN")}</Nav.Link>
            <Nav.Link href='#invest'>{t("INVEST")}</Nav.Link>
            <Nav.Link href='#refer'>{t("REFER")}</Nav.Link>
            <Nav.Link href='#contact'>{t("CONTACT")}</Nav.Link>
          </Nav>

          {i18n.language === "zh_cn" ? (
            <Button
              link={true}
              href={"#"}
              classes={"primary outline simple small"}
              value={"En"}
              onClick={() => changeLanguage("en_us")}
            />
          ) : (
            <Button
              link={true}
              href={"#"}
              classes={"primary outline simple small"}
              value={"中"}
              onClick={() => changeLanguage("zh_cn")}
            />
          )}

          <div style={{ paddingLeft: "5px" }}>
            <Button
              onClick={() =>
                window.open(`${siteURL}`, "_blank", "noopener,noreferrer")
              }
              classes={"primary outline simple small"}
              value={t("GO_TO_APP")}
            />
          </div>
          <div className={classes.hiddenElements}>
            <Socials />
          </div>
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
};

export default Header;
