import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import "./CountDownTimer.css";

const CountDownComponent = ({ timerCount }) => {
  const { t } = useTranslation();

  return _.isEmpty(timerCount) ? (
    <div className='countdown-container' style={{ margin: "2rem" }}>
      <h3>{t("PRE_SALE_END_TITLE")}</h3>
    </div>
  ) : (
    <>
      <div className='countdown-container'>
        <p>{t("PRE_SALE_TITLE")}</p>
      </div>
      <div className='countdown-container'>
        <div className='card'>
          <strong className='countdown-value'>{timerCount["days"]}</strong>
          <div className='countdown-unit'>{t("DAYS")}</div>
        </div>
        <div className='card'>
          <strong className='countdown-value'>{timerCount["hours"]}</strong>
          <div className='countdown-unit'>{t("HOURS")}</div>
        </div>
        <div className='card'>
          <strong className='countdown-value'>{timerCount["minutes"]}</strong>
          <div className='countdown-unit'>{t("MINS")}</div>
        </div>
      </div>
    </>
  );
};

export default CountDownComponent;
