import React from "react";

import HeroLayoutsTypeEleventh from "../layouts/HeroLayouts/HeroLayoutsTypeEleventh/HeroLayoutsTypeEleventh";
import PricingLayoutsTypeEighth from "../layouts/PricingLayouts/PricingLayoutsTypeEighth/PricingLayoutsTypeEighth";
import FootersLayoutsTypeCat from "../layouts/FootersLayouts/FootersLayoutsTypeCat/FootersLayoutsTypeCat";
import CTALayoutsTypeSubscribe from "../layouts/CTALayouts/CTALayoutsTypeSubscribe/CTALayoutsTypeSubscribe";
import TestimonialsLayoutsTypeEighth from "../layouts/TestimonialsLayouts/TestimonialsLayoutsTypeEighth/TestimonialsLayoutsTypeEighth";
import FeaturesLayoutsTypeAboutCoin from "../layouts/FeaturesLayouts/FeaturesLayoutsTypeAboutCoin/FeaturesLayoutsTypeAboutCoin";
import FeaturesLayoutsTypeSixteen from "../layouts/FeaturesLayouts/FeaturesLayoutsTypeSixteen/FeaturesLayoutsTypeSixteen";

function Home() {
  return (
    <>
      <HeroLayoutsTypeEleventh dark />
      <FeaturesLayoutsTypeAboutCoin dark />
      <FeaturesLayoutsTypeSixteen dark />
      <PricingLayoutsTypeEighth dark />
      <CTALayoutsTypeSubscribe dark />
      <TestimonialsLayoutsTypeEighth dark />
      <FootersLayoutsTypeCat dark />
    </>
  );
}

export default Home;
