import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./CTALayoutsTypeSubscribe.module.css";
import Form from "./SimpleForm/SimpleForm";
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";

function CTALayoutsTypeSubscribe(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section
        className={`section  ${classes.cta} ${props.dark ? `dark ${classes.dark}` : ""
          }`}
        id='refer'
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row className={classes.textRow}>
              <Col lg={8} md={8} className='mx-auto text-center'>
                <Fade top triggerOnce={true}>
                  <h1 className='title'>{t("SUBSCRIBE_TITLE")}</h1>
                </Fade>
                <Fade top delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {t("SUBSCRIBE_DESCRIPTION")}
                  </p>
                </Fade>
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Fade top delay={100} triggerOnce={true}>
                <Form dark />
              </Fade>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default CTALayoutsTypeSubscribe;
