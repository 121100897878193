import React from "react";
import Features from "./Features/Features";

import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import classes from "./FeaturesLayoutsTypeSixteen.module.css";
import FlippingCoin from "./flippingCoin/flippingCoin";

function FeaturesLayoutsTypeSixteen(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.features} ${props.dark ? `dark ${classes.dark}` : ""
          }`}
        id='invest'
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg={6}>
                <Fade down triggerOnce={true}>
                  <>
                    <FlippingCoin />
                  </>
                </Fade>
              </Col>

              <Col lg={6} className={classes.rightColumn}>
                <Features />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FeaturesLayoutsTypeSixteen;
