import React from "react";
import { LinkedIn, Twitter, Google } from "../../../../components/Icons/Icons";
import SocialsLinks from "../../../../components/Socials/Socials";
import Oval from "../../../../components/Frame/Frame";

const Socials = () => {
  return (
    <div className='socials'>
      <p>Follow us</p>
      <SocialsLinks>
        <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
          <Oval variant='normal'>
            <Twitter />
          </Oval>
        </a>
        <a
          href='https://linkedin.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Oval variant='normal'>
            <LinkedIn />
          </Oval>
        </a>
        <a href='https://google.com' target='_blank' rel='noopener noreferrer'>
          <Oval variant='normal'>
            <Google />
          </Oval>
        </a>
      </SocialsLinks>
    </div>
  );
};

export default Socials;
