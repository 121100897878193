import React from "react";
import { Col, Row } from "reactstrap";
import Feature from "../Feature/FeaturesTypeFirst";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import classes from "./Features.module.css";

const Features = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className={classes.featuresRow}>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down triggerOnce={true}>
            <Feature title={t("COIN_FEATURE1")} description={t("COIN_FEATURE1_DESCRIPTION")} />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={50} triggerOnce={true}>
            <Feature title={t("COIN_FEATURE2")} description={t("COIN_FEATURE2_DESCRIPTION")} />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={100} triggerOnce={true}>
            <Feature title={t("COIN_FEATURE3")} description={t("COIN_FEATURE3_DESCRIPTION")} />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={150} triggerOnce={true}>
            <Feature title={t("COIN_FEATURE4")} description={t("COIN_FEATURE4_DESCRIPTION")} />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={200} triggerOnce={true}>
            <Feature title={t("COIN_FEATURE5")} description={t("COIN_FEATURE5_DESCRIPTION")} />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={250} triggerOnce={true}>
            <Feature title={t("COIN_FEATURE6")} description={t("COIN_FEATURE6_DESCRIPTION")} />
          </Fade>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Features;
