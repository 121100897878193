import React from "react";

import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import Feature from "../Feature/FeaturesTypeEighth";
import {
  Basket,
  Bookmark,
  Email,
  Extension,
  Group,
  Home,
} from "../../../../components/Icons/Icons";
import Oval from "../../../../components/Frame/Frame";
import { Fade } from "react-awesome-reveal";
import classes from "./Features.module.css";

const Features = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className={classes.featuresRow}>
        <Col lg='4' md='6' sm='6' className={classes.item}>
          <Fade top delay={100} triggerOnce={true}>
            <Feature
              title={t("FEATURE1")}
              description={t("FEATURE1_DESCRIPTION")}
            >
              <Oval variant={"large"}>
                <Bookmark />
              </Oval>
            </Feature>
          </Fade>
        </Col>
        <Col lg='4' md='6' sm='6' className={classes.item}>
          <Fade top delay={150} triggerOnce={true}>
            <Feature
              title={t("FEATURE2")}
              description={t("FEATURE2_DESCRIPTION")}
            >
              <Oval variant={"large"}>
                <Home />
              </Oval>
            </Feature>
          </Fade>
        </Col>
        <Col lg='4' md='6' sm='6' className={classes.item}>
          <Fade top delay={200} triggerOnce={true}>
            <Feature
              title={t("FEATURE3")}
              description={t("FEATURE3_DESCRIPTION")}
            >
              <Oval variant={"large"}>
                <Basket />
              </Oval>
            </Feature>
          </Fade>
        </Col>
        <Col lg='4' md='6' sm='6' className={classes.item}>
          <Fade top delay={250} triggerOnce={true}>
            <Feature
              title={t("FEATURE4")}
              description={t("FEATURE4_DESCRIPTION")}
            >
              <Oval variant={"large"}>
                <Extension />
              </Oval>
            </Feature>
          </Fade>
        </Col>
        <Col lg='4' md='6' sm='6' className={classes.item}>
          <Fade top delay={300} triggerOnce={true}>
            <Feature
              title={t("FEATURE5")}
              description={t("FEATURE5_DESCRIPTION")}
            >
              <Oval variant={"large"}>
                <Group />
              </Oval>
            </Feature>
          </Fade>
        </Col>
        <Col lg='4' md='6' sm='6' className={classes.item}>
          <Fade top delay={400} triggerOnce={true}>
            <Feature
              title={t("FEATURE6")}
              description={t("FEATURE6_DESCRIPTION")}
            >
              <Oval variant={"large"}>
                <Email />
              </Oval>
            </Feature>
          </Fade>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Features;
